import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import SocialShare from '../components/SocialShare';
import LatestNews from '../components/LatestNews';
import { getCollectionLocaleFields, translateString } from '../utils';

const NewsSingleTemplate = ({
  data: { datoCmsNews, allDatoCmsNews },
  pageContext: { locale },
  location: pageData,
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    meta: { firstPublishedAt },
    category,
    featuredImage,
    modularBlocks,
  } = datoCmsNews;

  const { nodes } = getCollectionLocaleFields(allDatoCmsNews, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsNews}
      verticalText={translateString('News', locale)}
    >
      <main>
        <Banner
          overline={category.name}
          heading={title}
          text={`Posted ${firstPublishedAt}`}
          image={featuredImage}
          locale={locale}
        />
        <ModularBlocks
          items={modularBlocks}
          isNewsSingle={true}
          locale={locale}
        />
        <SocialShare
          heading="Share Post:"
          pageTitle={title}
          pageData={pageData}
        />
      </main>
      <LatestNews heading="In Other News" items={nodes} locale={locale} />
    </Layout>
  );
};

export const NewsSingleTemplateQuery = graphql`
  query NewsSingleTemplateQuery($id: String!) {
    datoCmsNews(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      meta {
        firstPublishedAt(formatString: "DD MMMM YYYY")
      }
      category {
        name
      }
      featuredImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...BlockquoteModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
      ...LinkFragment
    }
    allDatoCmsNews(
      filter: { id: { ne: $id }, slug: { ne: null } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      group(field: locale, limit: 2) {
        fieldValue
        nodes {
          ...NewsCardFragment
        }
      }
    }
  }
`;

export default NewsSingleTemplate;
